<template>
    <b-card no-body class="mb-2 p-1">
        <slot></slot>
    </b-card>
</template>

<script>
export default {
    name: 'VToolbar'
}
</script>

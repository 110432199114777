<template>
    <div>
        <v-toolbar>
            <b-row>
                <b-col cols="3">
                    <b-button :to="{name: 'InventoryActCreate'}" variant="success">
                        <b-icon-plus scale="1.3"/>
                    </b-button>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button @click="applyFilters" variant="primary">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(сбросить)</b-link>
                    </small>
                </b-col>
            </b-row>
            <b-collapse id="filters">
                <b-card-body>
                    <b-row>
                        <b-col md="4">
                            <b-form-group description="Дата (от)">
                                <b-form-datepicker
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder=""
                                    v-model="filters.dateFrom"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group description="Дата (до)">
                                <b-form-datepicker
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder=""
                                    v-model="filters.dateTo"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group description="Склад">
                                <storage-picker v-model="filters.storage"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </v-toolbar>

        <b-table
            :items="items"
            :fields="fields"
            head-variant="dark"
            :sort-by.sync="orderBy.by"
            :sort-desc.sync="orderBy.desc"
            no-sort-reset
            no-local-sorting
            @row-dblclicked="toForm"
            responsive
        >
            <template #cell(status)="data">
                <h5>
                    <b-badge v-if="data.item.status === ACT_STATUS_DRAFT" variant="info">Ожидает подтверждения</b-badge>
                    <b-badge v-else-if="data.item.status === ACT_STATUS_CONFIRMED" variant="success">Подтвержден
                    </b-badge>
                    <b-badge v-else-if="data.item.status === ACT_STATUS_CANCELED" variant="danger">Отменен</b-badge>
                </h5>
            </template>
            <template #cell(actions)="data">
                <b-button
                    @click="toForm(data.item)"
                    size="sm"
                    variant="warning"
                >
                    <b-icon-pencil-square/>
                </b-button>
            </template>
        </b-table>

        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>

import lsfMixin from '@utils/localStorageFilterMixin'
import moment from 'moment';
import {INVENTORY_ACTS_ENDPOINT} from '@utils/endpoints';
import StoragePicker from '@components/_common/StoragePicker';
import VToolbar from '@components/ui/toolbar/VToolbar';

const ACT_STATUS_DRAFT = "draft";
const ACT_STATUS_PENDING = "pending";
const ACT_STATUS_CONFIRMED = "confirmed";
const ACT_STATUS_CANCELED = "canceled";

const FILTER_PATTERN = {
    dateTo: null,
    dateFrom: null,
    storage: null,
};

export default {
    name: "InventoryActList",
    components: {StoragePicker, VToolbar},
    mixins: [lsfMixin],
    data() {
        return {
            ACT_STATUS_DRAFT,
            ACT_STATUS_PENDING,
            ACT_STATUS_CONFIRMED,
            ACT_STATUS_CANCELED,

            items: [],
            fields: [
                {
                    key: 'date',
                    label: 'Дата',
                    formatter: (date) => moment(date).format('DD.MM.YY HH:mm'),
                    sortable: true,
                },
                {
                    key: 'type',
                    label: 'Тип',
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center"
                },
                {
                    key: 'storage.name',
                    label: 'Склад',
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center"
                },
                {
                    key: 'author.fullname',
                    label: 'Пользователь',
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center"
                },
                {
                    key: 'movements_processed_at',
                    label: 'Остатки пересчитаны',
                    formatter: (date) => date ? 'Да' : 'Нет',
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center"
                },
                {
                    key: 'status',
                    label: 'Статус',
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center",
                },
                {
                    key: 'actions',
                    label: 'Действия',
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center"
                },
            ],
            filters: {
                dateTo: null,
                dateFrom: null,
                storage: null,
            },
            orderBy: {
                by: 'id',
                desc: true
            },
            meta: {
                current_page: 1,
                per_page: 25,
                total_rows: 1,
            },
        };
    },
    watch: {
        orderBy: {
            deep: true,
            handler() {
                this.applyFilters();
            }
        },
        filters: {
            deep: true,
            handler(value) {
                this.watchFilters(value);
            },
        },
    },
    methods: {
        applyFilters() {
            const params = {
                filters: {
                    date_from: this.filters.dateFrom,
                    date_to: this.filters.dateTo,
                    storage_id: this.filters.storage?.id,
                },
                page: this.meta.current_page,
                sort: this.orderBy.by,
                sortDirection: this.orderBy.desc ? 'desc' : 'asc',
            };

            this.$http.get(INVENTORY_ACTS_ENDPOINT, {params: params})
                .then(response => {
                    this.items = response.data.data;
                    this.meta.total_rows = response.data.meta.total;
                    this.meta.per_page = response.data.meta.per_page;
                    this.meta.current_page = response.data.meta.current_page;
                }).catch(this.$root.responseError);
        },
        toForm(item) {
            this.$router.push({name: 'InventoryActUpdate', params: {id: item.id}});
        },
    },
    mounted() {
        this.initFilters(this, FILTER_PATTERN);
    },
}
</script>
